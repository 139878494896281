import { RtbeConnection } from './rtbe-connection';
import { CloseCallback, MessageCallback, ConnectionOptions } from './types';

export let wsMap = new Map<string, RtbeConnection>();

export function getConnection<T, M>(
  team: string,
  view: string,
  messageCallback: MessageCallback<M>,
  closeCallback: CloseCallback,
  options?: ConnectionOptions
): RtbeConnection<T> {
  if (!team || !view || !messageCallback || !closeCallback) {
    throw new Error('All required parameters are not provided');
  }
  if (options?.adobeIOConfig) {
    const { adobeIOConfig } = options;
    if (!adobeIOConfig.token || !adobeIOConfig.imsOrg || !adobeIOConfig.product) {
      throw new Error('All required adobeIOConfig parameters are not provided');
    }
  }
  const key = team + '-' + view;
  if (wsMap.has(key)) {
    return wsMap.get(key) as RtbeConnection<T>;
  }
  const closeCb = (closeEvent: string) => {
    wsMap.delete(key);
    closeCallback(closeEvent);
  };
  const connection = new RtbeConnection<T>(
    team,
    view,
    messageCallback,
    closeCb,
    options
  );
  wsMap.set(key, connection);
  return connection;
}

export function closeConnection(connection: RtbeConnection) {
  connection.close();
}

export function getWsConnections() {
  return wsMap;
}
