import { useEffect } from 'react';
import { closeConnection, getConnection } from './rtbe-connection-manager';
import { CloseCallback, ConnectionOptions, MessageCallback } from './types';

export default <T, M>(
  team: string,
  view: string,
  messageCallback: MessageCallback<M>,
  closeCallback: CloseCallback,
  objects: T[],
  options?: ConnectionOptions
) => {
  const connection = getConnection<T, M>(
    team,
    view,
    messageCallback,
    closeCallback,
    options
  );
  useEffect(() => {
    connection.subscribe(objects);
    return () => closeConnection(connection);
  }, [connection, objects]);
};
