import {WebSocketSubject} from "rxjs/webSocket";
import {WsMessage} from "../rtbe-connection";
import {PagePresenceCallback} from "../types/page-presence-callback";
import IdleTracker from "idle-tracker";
import {PagePresence} from "../types/page-presence";
import {inactiveCode} from "../utils/color-codes";
import {ColorCodeManager} from "./color-code-manager";
import {PAGE_OBJECT_CODE, PRESENCE_OPERATION} from "../utils/constants";

export class PagePresenceHandler {

    private ws: WebSocketSubject<WsMessage> | null;
    private readonly pageId: string;
    private readonly pagePresenceCallback: (activeUsers: PagePresence[]) => void;
    private idleTracker: any;
    private isUserActive = true;

    constructor(ws: WebSocketSubject<WsMessage> | null, pageId: string, presenceCallback: PagePresenceCallback) {
        this.ws = ws;
        this.pageId = pageId;
        this.pagePresenceCallback = presenceCallback;
        this.idleTracker = new IdleTracker({timeout: 30000, onIdleCallback: this.onIdleCallback});
    }

    resubscribe(ws: WebSocketSubject<WsMessage> | null): void {
        this.ws = ws;
        this.subscribeToPagePresence();
    }

    public run(): void {
        ColorCodeManager.get().setPagePresenceEnabled(true);
        this.subscribeToPagePresence();
        this.idleTracker.start();
    }

    public handleSubscriptionCreation(): void {
        this.publishPagePresence();
    }

    private subscribeToPagePresence(): void {
        this.ws?.next(this.getPagePresencePayload('SUBSCRIBE'));
    }

    private publishPagePresence(): void {
        this.ws?.next(this.getPagePresencePublishPayload());
    }

    public stop(): void {
        ColorCodeManager.get().setPagePresenceEnabled(false);
        this.ws?.next(this.getPagePresencePayload('UNSUBSCRIBE'));
        this.idleTracker.end();
        this.pagePresenceCallback([]);
    }

    public async handlePresenceMessage(message: any): Promise<void> {
        const presence: PagePresence[] = [];
        const userIds = message?.users
            .map(user => user.userId);
        const userColorsMap = ColorCodeManager.get().getPagePresenceUserColors(userIds);
        for (const eachUser of message?.users) {
            const userId = eachUser.userId;
            const userName = eachUser.userName;
            const active = eachUser.active;
            presence.push({
                userId,
                userName,
                active,
                colorCode: active ? userColorsMap.get(userId) as string : inactiveCode
            });
        }
        this.pagePresenceCallback(presence);
    }

    private onIdleCallback = (payload) => {
        this.isUserActive = !payload.idle;
        this.publishPagePresence();
    }

    private getPagePresencePublishPayload(): WsMessage {
        return {
            operation: "PUBLISH",
            objects: [
                {
                    objectCode: PAGE_OBJECT_CODE,
                    objectId: this.pageId,
                    operation: PRESENCE_OPERATION,
                    time: Date.now(),
                    active: this.isUserActive
                }
            ]
        } as WsMessage;
    }

    private getPagePresencePayload(operation: string): WsMessage {
        return {
            "operation": operation,
            "objects": [
                {
                    "objectCode": PAGE_OBJECT_CODE,
                    "objectId": this.pageId,
                    "operation": PRESENCE_OPERATION
                }
            ]
        } as WsMessage;
    }
}