import {getColorCode} from "../utils/color-codes";

export class ColorCodeManager {

    private static colorCodeManager;
    private userColorsMap = new Map<string, string>();
    private assignedColors = new Set<string>();
    private pagePresenceEnabled = false;

    private constructor() {
        // Empty constructor
    }

    public static get(): ColorCodeManager {
        if (!this.colorCodeManager) {
            this.colorCodeManager = new ColorCodeManager();
        }
        return this.colorCodeManager;
    }

    private cleanupColors(userIds: string[]): void {
        const userIdSet = new Set(userIds);
        this.userColorsMap.forEach((colorCode, userId) => {
            // Remove the color code if the user is not present in the new list (userIds). This frees up the color code for new users
            if (!userIdSet.has(userId)) {
                this.assignedColors.delete(colorCode);
                this.userColorsMap.delete(userId);
            }
        });
    }

    private assignColors(userIds: string[]): void {
        userIds.forEach(userId => {
            // Assign color code if the user is a new user (not present in the map)
            if (!this.userColorsMap.has(userId)) {
                const colorCode = getColorCode(userId, this.assignedColors);
                this.userColorsMap.set(userId, colorCode);
                this.assignedColors.add(colorCode);
            }
        });
    }

    public getPagePresenceUserColors(userIds: string[]): Map<string, string> {
        this.cleanupColors(userIds);
        this.assignColors(userIds);
        return this.userColorsMap;
    }

    public getComponentPresenceUserColors(userIds: string[]): Map<string, string> {
        // If page presence is not enabled, this should work similar to getPagePresenceUserColors
        if (!this.pagePresenceEnabled) {
            return this.getPagePresenceUserColors(userIds);
        }
        // If page presence is enabled, the users in the component presence will be subset of users in the page presence. So we can reuse the colors assigned for page presence.
        // If the component presence message is the first message received through websocket, the userColorsMap will be empty. In that case, we need to assign colors.
        if (this.userColorsMap.size === 0) {
            this.assignColors(userIds);
        }
        return this.userColorsMap;
    }

    public getCursorPresenceUserColors(userIds: string[]): Map<string, string> {
        return this.getComponentPresenceUserColors(userIds);
    }

    public setPagePresenceEnabled(value: boolean): void {
        this.pagePresenceEnabled = value;
    }
}