import {WebSocketSubject} from "rxjs/webSocket";
import {WsMessage} from "../rtbe-connection";
import {ComponentPresenceCallback} from "../types/component-presence-callback";
import {inactiveCode} from "../utils/color-codes";
import {UserPresence} from "../types/user-presence";
import {ComponentPresence} from "../types/component-presence";
import IdleTracker from "idle-tracker";
import {ColorCodeManager} from "./color-code-manager";
import {COMPONENT_OBJECT_CODE, PAGE_OBJECT_CODE, PRESENCE_OPERATION} from "../utils/constants";

export class ComponentPresenceHandler {

    private ws: WebSocketSubject<WsMessage> | null;
    private readonly pageId: string;
    private readonly componentPresenceCallback: ComponentPresenceCallback;
    private componentId: string = '';
    private editing: boolean = false;
    private idleTracker: any;
    private isUserActive = true;

    constructor(ws: WebSocketSubject<WsMessage> | null, pageId: string, presenceCallback: ComponentPresenceCallback) {
        this.ws = ws;
        this.pageId = pageId;
        this.componentPresenceCallback = presenceCallback;
        this.idleTracker = new IdleTracker({timeout: 30000, onIdleCallback: this.onIdleCallback});
    }

    resubscribe(ws: WebSocketSubject<WsMessage> | null): void {
        this.ws = ws;
        this.subscribeToComponentPresence();
    }

    public run(): void {
        this.subscribeToComponentPresence();
        this.idleTracker.start();
    }

    public handleSubscriptionCreation(): void {
        this.publishComponentPresence();
    }

    private subscribeToComponentPresence(): void {
        this.ws?.next(this.getComponentPresencePayload('SUBSCRIBE'));
    }

    private publishComponentPresence(): void {
        if (!this.componentId) {
            return;
        }
        this.ws?.next(this.getComponentPresencePublishPayload());
    }

    public stop(): void {
        this.ws?.next(this.getComponentPresencePayload('UNSUBSCRIBE'));
        this.idleTracker.end();
        this.componentPresenceCallback([]);
    }

    public async handlePresenceMessage(message: any): Promise<void> {
        const componentPresences: ComponentPresence[] = [];
        const userIds = ComponentPresenceHandler.getUniqueUserIds(message);
        const userColorsMap = ColorCodeManager.get().getComponentPresenceUserColors(userIds);
        for (let eachComponent of message?.components) {
            const componentId = eachComponent.componentId;
            const users: UserPresence[] = [];
            for (let eachUser of eachComponent.users) {
                const userId = eachUser.userId;
                const userName = eachUser.userName;
                users.push({
                    userId,
                    userName,
                    colorCode: eachUser.active ? userColorsMap.get(userId) as string : inactiveCode,
                    active: eachUser.active,
                    editing: eachUser.editing
                });
            }
            componentPresences.push({
                componentId,
                userPresence: users
            });
        }
        this.componentPresenceCallback(componentPresences);
    }

    public updateComponentPresence(componentId: string, editing: boolean) {
        if (!componentId) {
            return;
        }
        if (this.componentId === componentId && this.editing === editing) {
            return;
        }
        const isInitialUpdate = !this.componentId; // If the componentId is not set, it means this is the initial component presence update.
        this.componentId = componentId;
        this.editing = editing;
        // For initial component presence update, the publishing will happen in handleSubscriptionCreation after the subscription is created. Subsequent publishes will happen here.
        if (!isInitialUpdate) {
            this.publishComponentPresence();
        }
    }

    private onIdleCallback = (payload) => {
        this.isUserActive = !payload.idle;
        this.publishComponentPresence();
    }

    private getComponentPresencePublishPayload(): WsMessage {
        return {
            operation: "PUBLISH",
            objects: [
                {
                    objectCode: COMPONENT_OBJECT_CODE,
                    objectId: this.componentId,
                    parentObjectCode: PAGE_OBJECT_CODE,
                    parentObjectId: this.pageId,
                    operation: PRESENCE_OPERATION,
                    time: Date.now(),
                    active: this.isUserActive,
                    editing: this.editing
                }
            ]
        } as WsMessage;
    }

    private getComponentPresencePayload(operation: string): WsMessage {
        return {
            "operation": operation,
            "objects": [
                {
                    "objectCode": COMPONENT_OBJECT_CODE,
                    "filter": {
                        "fieldName": PAGE_OBJECT_CODE,
                        "fieldValue": this.pageId,
                        "operation": PRESENCE_OPERATION
                    }
                }
            ]
        } as WsMessage;
    }

    private static getUniqueUserIds(message: any): string[] {
        const userIds: Set<string> = message?.components
                .flatMap(component => component.users)
                .reduce((uniqueUserIds, user) => {
                    uniqueUserIds.add(user.userId);
                    return uniqueUserIds;
                }, new Set<string>());
        return Array.from(userIds);
    }
}