import hash from 'object-hash';

export class SubscriptionManager {
  public activeSubscriptions: Map<string, any>;

  constructor() {
    this.activeSubscriptions = new Map();
  }

  addSubscriptions(objects) {
    objects.forEach(object => {
      const key = hash(object);
      this.activeSubscriptions.set(key, object);
    });
  }

  removeSubscriptions(objects) {
    objects.forEach(object => {
      const key = hash(object);
      this.activeSubscriptions.delete(key);
    });
  }
}
