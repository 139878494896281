export const inactiveCode = '#d3d3d3';
export const colorCodes = [
    '#4a75ff',
    '#05a35d',
    '#e86a00',
    '#f03823',
    '#9a7b4d',
    '#52a118',
    '#b86c46',
    '#8eac00',
    '#30a7fe',
    '#c843dc',
    '#796afd',
    '#f02d6e',
    '#e433a1',
    '#a65ce6',
    '#0ca186',
    '#0c8c99',
    '#d29501'
]

export function getColorCode(input: string, assignedColors: Set<string>): string {
    const hash = getHashNumber(input);
    const lengthReached = assignedColors.size >= colorCodes.length;
    if (!lengthReached && assignedColors.has(colorCodes[hash])) {
        return getColorCode(input + '1', assignedColors);
    }
    return colorCodes[hash];
}

function getHashNumber(input: string): number {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i);
    }
    return Math.abs(hash) % colorCodes.length;
}
